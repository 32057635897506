
import { createApp, reactive, nextTick } from '/js/petite-vue/dist/petite-vue.es.js'
import { createAtom } from "/js/vendor/xoid.custom.js"
import { reactiveAtom } from "/js/reactiveAtom.js"
import { categsTree } from "/js/store/index.js"
import { Offcanvas } from "/js/vendor/bootstrap.custom.js"


const store = createAtom(read => ({
	tree: read(categsTree),
}))

const params = reactive({
	touch: true,
	screen: 'desktop',
	navActive: false,
	rootEl: null,
	// для десктопного режима работы
	activeId: '',
	// для мобильного режима работы
	activeItems: [],
	selectedNavs: []
})


function TreeItem(item) {
	return {
		item,
		showSubnav(e) {
			if (params.navActive) {
				let navItem = e.currentTarget.closest('.catalog-nav__section')
				if (navItem) params.activeId = item.id;
			}
		},
		hideSubnav(e) {
			if (e.relatedTarget?.closest('.catalog-nav__section:not(.active)')) params.activeId = '';
		},
		mounted(el) {

		},
		$template: '#cat-nav__item-tpl',
	}
}


function Subnav(subnav) {
	return {
		subnav,
		showSubnavMobile(item, idx) {
			let catNav = params.rootEl.querySelector('.catalog-nav__list')

			if ( params.activeItems.find(o => o.id === item.id) ) {
				let currEl = catNav.querySelector(`[data-index="${idx}"]`)

				currEl.nextElementSibling.classList.remove('show')
				currEl.scrollIntoView({ behavior: 'smooth', inline: 'end' })

				setTimeout(() => {
					params.activeItems.splice(idx, Infinity)
					params.selectedNavs.splice(idx, Infinity)
				}, 350)
			}
			else {
				let tree = this.subnav.find(o => o.id === item.id).children

				if (tree) {
					params.activeItems.splice(idx, Infinity, item)
					params.selectedNavs.splice(idx, Infinity, tree)

					nextTick(() => {
						let currEl = catNav.querySelector(`[data-index="${idx}"]`)

						currEl.nextElementSibling.scrollIntoView({ behavior: 'smooth', inline: 'end' })
						setTimeout(() => currEl.nextElementSibling.classList.add('show'), 10)
					})
				}
			}
		},
		$template: '#cat-nav__item-mobile-tpl'
	}
}


enquire.register('screen and (hover: hover) and (pointer: fine)', {
	match: () => {
		params.touch = false
	},
	unmatch: () => {
		params.touch = true
	},
})

enquire.register('screen and (min-width: 760.02px)', {
	match: () => {
		params.screen = 'desktop'
	}
})
enquire.register('screen and (min-width: 500.02px) and (max-width: 760px)', {
	match: () => {
		params.screen = 'tablet'
	},
})
enquire.register('screen and (max-width: 500px)', {
	match: () => {
		params.screen = 'smart'
	},
})


createApp({
	TreeItem,
	Subnav,
	store: reactiveAtom(store),
	params,
	offcanvas: null,

	get selectedNavs () {
		return this.store.tree?.length ? [this.store.tree].concat(this.params.selectedNavs) : []
	},

	mounted(root) {
		params.rootEl = root
		this.offcanvas = Offcanvas.getOrCreateInstance(root)

		root.addEventListener('show.bs.offcanvas', (e) => {
			let firstElem = this.store.tree[0]
			setTimeout(() => {
				params.navActive = true
				params.activeId = firstElem ? firstElem.id : ''
			}, 300)
		})
		root.addEventListener('hide.bs.offcanvas', (e) => {
			params.navActive = false
			params.activeId = ''
		})
		root.addEventListener('hidden.bs.offcanvas', (e) => {
			params.activeItems = []
			params.selectedNavs = []
		})
	},

	closeNav() {
		params.activeId = ''
		params.navActive = false
		setTimeout(() => this.offcanvas.hide(), 100)
	},

	$delimiters: ['[[', ']]'],
}).mount('#cat-nav-offcanvas')
